import React, { Fragment } from "react"
import { Link } from "gatsby"
import GostouDoDesafio from '@/components/GostouDoDesafio'
import Img1 from '@/images/new/landing-page/img1.png'
import Img2 from '@/images/new/landing-page/img2.png'
import Img3 from '@/images/new/landing-page/smile.svg'
import Phone from '@/images/new/landing-page/phone.png'

const Finalistas = ({}) => {
  return (
    <Fragment>
      <section className="pageLanding PT-160" style={{ background: '#212121', paddingBottom: 56 }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 d-none-img-mob">
              <img src={Img1} style={{ marginBottom: 32 }} />
            </div>
            <div className="col-lg-5">
              <div className="banner_content PT-130">
                <h2 style={{ color: '#F7F7F7', marginBottom: 32 }}>Chegou a hora de fazer parte do desafio de transformar o mundo</h2>
                <h4 style={{ color: '#F7F7F7', marginBottom: 72, maxWidth: 430, width: '100%' }}>Faça sua inscrição na Sapientia, apresente a sua ideia e tenha a chance de tirar o seu projeto do papel.</h4>
                <a href='https://fractalid.fractaltecnologia.com.br/signup?app_id=50&url_redirect=https%3A%2F%2Fapp.olimpiadadofuturo.com.br%2F' target='_blank' className="quero-participar-blue_btn">QUERO PARTICIPAR!</a>
              </div>
            </div>
            <div className="col-lg-7 d-none-img-desk">
              <img className="img-size" src={Img1} />
            </div>
          </div>
        </div>
      </section>
      <section style={{ background: '#F7F7F7', padding: '112px 0' }}>
        <div className="container">
          <div className="row" style={{ justifyContent: 'center' }}>
            <div className="col-lg-4">
              <div><img className="img-size" src={Phone} /></div>
            </div>
            <div className="col-lg-6">
              <div style={{ width: '100%', maxWidth: 626 }}>
                <h2 className="land-title-b" style={{ marginBottom: 56 }}>Olá, estamos felizes em te receber aqui!</h2>
                <p className="land-paragraph">Se você chegou até aqui é sinal de que está comprometido(a) com a transformação, que tem algo a dizer e quer mudar o lugar onde você está e, até mesmo, o Mundo!</p>
                <p className="land-paragraph" style={{ marginBottom: 72 }}>Queremos te apresentar a Sapientia, uma Olimpíada que tem o objetivo de impulsionar alunos na construção de projetos reais, para solução de problemas reais.</p>
                <a href='https://fractalid.fractaltecnologia.com.br/signup?app_id=50&url_redirect=https%3A%2F%2Fapp.olimpiadadofuturo.com.br%2F' target='_blank' className="quero-participar-black_btn">QUERO PARTICIPAR!</a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section style={{ background: '#F7F7F7', paddingBottom: '112px' }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-6 d-none-img-mob">
              <img className="img-size" src={Img2} style={{ marginBottom: 32 }} />
            </div>
            <div className="col-lg-6">
              <h2 className="land-title-b" style={{ marginBottom: 56 }}>Como funciona a sapientia</h2>
              <p className="land-paragraph">Após as fases iniciais da Olimpíada, nós o ajudaremos a escolher um problema real, que estará alinhado com algum dos 17 Objetivos de Desenvolvimento Sustentável (ODS), da Agenda 2030 da ONU, enfrentado por sua cidade ou pelo mundo.</p>
              <p className="land-paragraph">Juntos, o acompanharemos no desenvolvimento deste projeto: auxiliando na estrutura do Pitch de Vendas e na formatação de Slides para comunicar a ideia de maneira clara.</p>
              <p className="land-paragraph">Você também terá uma mentoria a respeito da estratégia, do planejamento financeiro e do marketing em relação a solução proposta!</p>
              <p className="land-paragraph" style={{ marginBottom: 72 }}>Fique tranquilo(a), porque em toda esta jornada, você contará com a supervisão e colaboração de especialistas em todas as etapas!</p>
              <a href='https://fractalid.fractaltecnologia.com.br/signup?app_id=50&url_redirect=https%3A%2F%2Fapp.olimpiadadofuturo.com.br%2F' target='_blank' className="quero-participar-black_btn">QUERO PARTICIPAR!</a>
            </div>
            <div className="col-lg-6 d-none-img-desk">
              <img className="img-size" src={Img2} style={{ marginBottom: 32 }} />
            </div>
          </div>
        </div>
      </section>
      <GostouDoDesafio />
      <section style={{ background: '#F7F7F7', padding: '112px 0' }}>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center ">
              <p className="cta-paragraph">Mas não se preocupe, pois assim que você se inscrever, receberá um e-mail de boas-vindas com todas as informações necessárias. A partir daí é só ficar de olho em seu e-mail, pois toda a comunicação será por este canal.</p>
              <p className="cta-paragraph">Boa sorte e nos vemos lá dentro da Sapientia!</p>
              <p style={{ marginBottom: 32 }}><img src={Img3} /></p>
              <a href='https://fractalid.fractaltecnologia.com.br/signup?app_id=50&url_redirect=https%3A%2F%2Fapp.olimpiadadofuturo.com.br%2F' target='_blank' className="quero-participar-black_btn">QUERO PARTICIPAR!</a>
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default Finalistas;
