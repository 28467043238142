import React from "react"
import { JornadaSapientiaData } from "@/data"
import { Col, Container, Row } from "react-bootstrap"
import { Link } from "gatsby"
import Etapa1 from "@/images/new/jornadas/1a-etapa.svg"
import Etapa2 from "@/images/new/jornadas/2a-etapa.svg"
import Etapa3 from "@/images/new/jornadas/3a-etapa.svg"
import EtapaGrupo1 from "@/images/new/jornadas/1a-etapa-grupo.svg"
import EtapaGrupo2 from "@/images/new/jornadas/2a-etapa-grupo.svg"

const JornadaSapientia = () => {
  const { sectionContent } = JornadaSapientiaData;
  return (
    <section className="jornada-sapientia">
      <Container>
        <Row>
          <Col lg={12} md={12} sm={12}>
            <h2 className="title-w text-center" style={{ marginBottom: 32, lineHeight: '48px', fontSize: '48px' }}>Gostou do desafio?</h2>
            <h5 className="sub-jornada text-center" style={{ marginBottom: 72, fontSize: '20px' }}>Agora, confira no infográfico abaixo as etapas da Jornada Sapientia que você fará parte!</h5>
						<h4 className="sub-title-jornada text-center" style={{ marginBottom: 72 }}>{sectionContent.textTitle}</h4>
          </Col>
        </Row>
        <Row className="fases-individuais">
          <Col lg={4} md={4} sm={12}>
            <div className="text-center MB-72">
              <img src={Etapa1} alt='' />
              <h4>Primeira etapa</h4>
              <p>A jornada Sapientia começa com a aplicação de uma prova objetiva, com foco nos principais <span>problemas</span> contemporâneos.</p>
            </div>
          </Col>
          <Col lg={4} md={4} sm={12}>
            <div className="text-center MB-72">
              <img src={Etapa2} alt='' />
              <h4>Segunda etapa</h4>
              <p>Nesta segunda etapa, uma nova prova objetiva é aplicada, agora, sobre grandes <span>soluções</span> para problemas contemporâneos.</p>
            </div>
          </Col>
          <Col lg={4} md={4} sm={12}>
            <div className="text-center">
              <img src={Etapa3} alt='' />
              <h4>Terceira etapa</h4>
              <p>Nesta etapa acontece a seleção de pré-projetos: o aluno apresenta uma solução para um problema real identificado no seu contexto.</p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12} sm={12}>
            <h5 className="sub-jornada text-center" style={{ marginTop: 72, marginBottom: 122, color: "#AC88FD" }}>Avançam em cada etapa apenas 10% dos participantes.</h5>
          </Col>
        </Row>
        <div className="line-border" />
        <Row>
          <Col lg={12} md={12} sm={12}>
            <h4 className="sub-title-jornada text-center" style={{ margin: "72px 0" }}>{sectionContent.textTitle2}</h4>
          </Col>
        </Row>
        <Row className="fases-individuais" style={{ display: 'flex', justifyContent: 'center' }}>
          <Col lg={4} md={4} sm={12}>
            <div className="text-center MB-72">
              <img src={EtapaGrupo1} alt='' />
              <h4>Quarta etapa</h4>
              <p>Entrega de projetos</p>
            </div>
          </Col>
          <Col lg={4} md={4} sm={12}>
            <div className="text-center">
              <img src={EtapaGrupo2} alt='' />
              <h4>Quinta etapa</h4>
              <p>Apresentação de projetos na grande final</p>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12} sm={12}>
            <h5 className="sub-jornada text-center" style={{ marginTop: 72, marginBottom: 122, color: "#AC88FD" }}>As fases em grupo são definidas por um desenvolvimento de pitch de plano de negócios, que deve ser apresentado por meio de um vídeo inspirador, um site sobre o projeto e uma apresentação (em PPT) contendo todas as informações do projeto</h5>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default JornadaSapientia;

